<template>
  <div>
    <header-slot></header-slot>
    <b-card no-body class="mb-0">
      <filter-slot
        :filter="visibleFilters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @onChangeCurrentPage="$refs.rcTable.refresh()"
        @reload="$refs['rcTable'].refresh()"
      >
        <b-table
          id="rcTable"
          slot="table"
          ref="rcTable"
          :items="myProvider"
          :fields="fields"
          :filter="filter"
          table-class="text-nowrap"
          responsive="sm"
          no-border-collapse
          show-empty
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(client)="data">
            <div class="text-warning font-weight-bolder">
              {{ data.item.client }}
            </div>
            <div>
              {{ data.item.client_phone }}
            </div>
          </template>

          <template #cell(account)="data">
            <div>
              <router-link v-if="data.item.type_review == 1"
                class
                target="_blank"
                :to="`/quality/clients/account/${data.item.client_account_id}`"
                >{{ data.item.client_account }}</router-link
              >
              <b-badge v-else variant="light-primary">
                LEAD
              </b-badge>
            </div>
          </template>

          <template #cell(status)="data">
            <div v-if="data.item.type_review == 1" class="w-10">
              <status-account :account="data.item"/>
            </div>
            <div v-else>
              <b-badge :variant="statusPotentialLead(data.item.name_status)[1]">
                {{ statusPotentialLead(data.item.name_status)[0] }}
              </b-badge>
            </div>
          </template>

          <template #cell(service)="data">
            <div>
              {{ data.item.service }}
            </div>
            <div>
              {{ data.item.service_phone }}
            </div>
          </template>

          <template #cell(note)="data">
            <div>
              <feather-icon
                icon="FileTextIcon"
                class="cursor-pointer"
                v-b-tooltip.hover
                title="Show note"
                @click="openModalNote(data.item)"
              ></feather-icon>
            </div>
            <div>
              {{ data.item.created_at | myGlobal }}
            </div>
          </template>
        </b-table>
      </filter-slot>
    </b-card>

    <modal-show-note
      v-if="showModalNote"
      :note-account-id="currentNoteAccountId"
      @hide="showModalNote = false"
      :type-note="typeNote"
    />
  </div>
</template>
    
    <script>
import Filters from "@/views/quality/views/notes-without-calls/data/filters.data.js";
import Fields from "@/views/quality/views/notes-without-calls/data/fields.data.js";
import { mapGetters } from "vuex";
import NotesWcService from "@/views/quality/views/notes-without-calls/services/notes-wc.service.js";
import ModalShowNote from "@/views/quality/views/calls-review/components/ModalShowNote.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  name: "NotesWC",
  components: { ModalShowNote, StatusAccount },
  data() {
    return {
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Number, Client, Agent...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      filter: Filters,
      startPage: null,
      toPage: null,
      items: [],
      sortBy: "1",
      sortDesc: true,
      showModalRating: false,
      showModalNote: false,
      typeNote: 1,

      currentReview: null,
      currentAgent: null,
      currentClient: null,
      currentNote: "",
      currentDateNote: null,
      currentNoteAccountId: null,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    fields() {
      let fields = [...Fields];
      return fields.filter((filter) => filter.visible);
    },

    visibleFilters() {
      let filters = [...Filters];

      return filters;
    },
  },
  methods: {
    resetAllFilters() {
      this.filter.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
    },

    resetSearch() {
      this.searchInput = "";
    },

    async myProvider(ctx) {
      try {
        this.addPreloader();
        const params = {
          search_txt: this.filterPrincipal.model,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          from_date: this.filter[0].model,
          to_date: this.filter[1].model,
          program: this.filter[2].model,
          module: this.filter[3].model,
          sort_desc: ctx.sortDesc ? "desc" : "asc",
          sort_by: ctx.sortBy,
        };
        const { data } = await NotesWcService.index(params);
        // this.startPage = data.from ? data.from : 0;
        // this.paginate.perPage = data.per_page;
        // this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to ? data.to : 0;
        return data.data || [];
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      } finally {
        this.removePreloader();
      }
    },
    getCallStatus(status) {
      if (status === "INBOUND") {
        return ["light-primary", "PhoneIncomingIcon", "text-primary"];
      }

      return ["light-success", "PhoneOutgoingIcon", "text-success"];
    },

    refreshComponents() {
      this.$refs.rcTable.refresh();
      this.showModalRating = false;
    },

    openModalRating(item) {
      this.currentReview = item.id;
      this.currentAgent = item.agent;
      this.currentClient = item.client;
      this.showModalRating = true;
    },

    openModalNote(note) {
      this.typeNote = note.type_review; // 1 note_account, 2 note
      this.currentNoteAccountId =
        note.type_review == 1 ? note.note_account_id : note.note_id;
      this.showModalNote = true;
    },

    statusPotentialLead(status) {
      switch (status) {
        case "1":
          return ["Potential", "light-success"];
        case "2":
          return ["No potential", "light-info"];
        case "3":
          return ["Confirm", "light-primary"];
        case "4":
          return ["Recovery", "light-warning"];
      }
    },
  },
};
</script>
    
    <style scoped>
</style>