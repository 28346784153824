import { amgApi } from '@/service/axios'
class CallsReviewService {
  async index(payload) {
    try {
      const data = await amgApi.post('quality/calls-review/index', payload)
      return data
    } catch (error) {
      console.log('Something went wrong on index:', error)
      throw error
    }
  }

  async store() {
    try {
      const data = await amgApi.post('quality/calls-review/store')
      return data
    } catch (error) {
      console.log('Something went wrong on store:', error)
      throw error
    }
  }

  async update(payload){
    try {
        const data = await amgApi.post('quality/calls-review/update', payload)
        return data
      } catch (error) {
        console.log('Something went wrong on update:', error)
        throw error
      }
  }

  async getCallsByNote(payload) {
    try {
      const { data } = await amgApi.post('quality/calls-review/show', payload)
      return data;
    } catch (error) {
      console.log('Something went wrong on getCallByNote:', error)
      throw error
    }
  }
}
export default new CallsReviewService()