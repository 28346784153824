export default [
    {
        key: "client",
        label: "client",
        thClass: "text-left",
        tdClass: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "account",
        label: "account",
        thClass: "text-left",
        tdClass: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "status",
        label: "status",
        thClass: "text-left",
        tdClass: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "service",
        label: "service",
        thClass: "text-left",
        tdClass: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "note",
        label: "note",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "agent",
        label: "agent",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
        visible: true,
    },
]