<template>
  <div class="px-2 mt-1">
    <header-slot></header-slot>

    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'calls-review-pending' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3 h-full', 'px-3', bgTabsNavs]"
        >PENDING
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'calls-review-done' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3 h-full', 'px-3', bgTabsNavs]"
        >DONE
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>