import { amgApi } from '@/service/axios'
class NotesWCService {
  async index(payload) {
    try {
      const data = await amgApi.post('quality/calls-wc/index', payload)
      return data
    } catch (error) {
      console.log('Something went wrong on index:', error)
      throw error
    }
  }
}
export default new NotesWCService()