<template>
  <b-modal
    v-model="showModal"
    title="All Notes"
    title-tag="h3"
    hide-footer
    size="xmd"
    scrollable
    @hide="$emit('hide')"
  >
    <div class="header-card-call" :class="{ dark: isDarkSkin }">
      <div class="d-flex p-2 justify-content-between gap-10 w-100">
        <div class="header-card-call-child" :class="{ dark: isDarkSkin }">
          <div class="header-card-call-child-name">
            Client | {{ clientName }}
          </div>
        </div>
        <div class="header-card-call-child" :class="{ dark: isDarkSkin }">
          <div class="header-card-call-child-name">
            {{ typeNote == 1 ? 'Program' : 'Module' }} | {{ programName }}
          </div>
        </div>
      </div>
    </div>
    <div class="card-note-cmp mt-2" :class="{ dark: isDarkSkin }">
      <div
        v-for="note in notes"
        :key="note.id"
        class="rounded card-note"
        :class="isDarkSkin ? 'note-card-note-dark' : 'note-card-note-light'"
      >
        <div class="clearfix mb-2">
          <div
            style="font-size: 14px; margin-bottom: 5px; font-weight: 600"
            class="text-primary"
          >
            {{ note.note_title }}
          </div>

          <div>
            <div v-html="note.note_contenst"></div>
          </div>
        </div>
        <div class="d-flex justify-content-start">
          <div class="d-flex">
            <div>
              <feather-icon size="15" icon="PhoneCallIcon" />
            </div>
            <div class="pl-1" style="font-weight: 600">
              Call Record:
              <span style="font-weight: 400">{{
                note.list_audio.length === 0 ? "No record" : ""
              }}</span>
            </div>
          </div>
        </div>
        <div v-if="note.list_audio.length > 0" style="overflow-x: auto">
          <div class="d-flex gap-10 my-1">
            <div
              style="
                padding: 12px 10px;
                margin-right: 10px;
                display: inline-block;
                border: 1px solid #e0e0e0;
                border-radius: 5px;
                background: #f5f5f5;
              "
              v-for="(audio, index) in note.list_audio"
              :key="audio"
            >
              <wave-surfer-player
                style="height: 1.5rem"
                :url="audio"
                :options="audioOptions"
                :wave-surfer-id="`wave-surfer-${index}`"
                :elapsed-time-width="95"
                show-duration
              />
            </div>
          </div>
        </div>
        <div
          v-if="isDarkSkin"
          style="
            border: 1px solid rgb(241, 239, 239);
            margin-bottom: 12px;
            margin-top: 5px;
          "
        />
        <div
          v-else
          style="
            border: 1px solid rgba(231, 235, 246, 1);
            margin-bottom: 12px;
            margin-top: 5px;
          "
        />
        <div class="d-flex justify-content-between">
          <div style="font-size: 13px">
            {{ note.created_at | myDateGlobalWithHour }}
          </div>
          <div style="font-size: 13px">
            {{ note.created_by }}
          </div>
        </div>
      </div>
      <hr v-if="statusReview == 2" />
      <template v-if="statusReview == 2">
        <div
          v-for="note in notes"
          :key="note.id"
          class="rounded card-note"
          :class="isDarkSkin ? 'note-card-note-dark' : 'note-card-note-light'"
        >
          <div class="clearfix mb-2">
            <div
              style="font-size: 14px; margin-bottom: 5px; font-weight: 600"
              class="text-primary"
            >
              RATING
            </div>
            <div class="container-star">
              <template v-for="item in stars">
                <feather-icon
                  :key="item"
                  icon="StarIcon"
                  size="44"
                  class="star"
                  style="font-weight: lighter !important"
                  :class="{
                    'star-active': starsActive(note.score).includes(item),
                  }"
                />
              </template>
            </div>
            <div class="text-warning h5 my-1">COMMENTARY:</div>
            <div class="comment-vhtml" v-html="note.comment"></div>
          </div>
        </div>
      </template>
    </div>
  </b-modal>
</template>

<script>
import CallsReviewService from "@/views/quality/views/calls-review/services/calls-review.service.js";
import WaveSurferPlayer from "@/views/management/views/approval-process/settlement-approval/components/WaveSurferPlayer.vue";

export default {
  components: {
    WaveSurferPlayer,
  },
  props: {
    noteAccountId: {
      type: [String, Number],
      required: true,
    },
    statusReview: {
      type: Number,
      required: true,
    },
    typeNote: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      clientName: null,
      programName: null,
      notes: [],
      content:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
      showModal: true,
      stars: [1, 2, 3, 4, 5],
      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 3,
        backend: "MediaElement",
        minPxPerSec: 5,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
    };
  },
  async created() {
    await this.getCallsByNote();
  },

  methods: {
    starsActive(note) {
      return this.stars.slice(0, note);
    },
    regexNoHTML(text) {
      const newText = text.replace(/<[^>]*>/g, "");
      return newText;
    },
    async getCallsByNote() {
      const params = {
        type: "calls_by_note",
        note_account_id: this.noteAccountId,
        type_note: this.typeNote
      };
      const { data } = await CallsReviewService.getCallsByNote(params);
      this.clientName = data[0].client_name;
      this.programName = data[0].program_name;
      this.notes = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.note-card-note-light {
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  padding: 10px 20px;
  width: 100%;
}

.note-card-note-dark {
  margin: 0 auto;
  padding: 10px 20px;
  width: 100%;
  background: #ffffff0f;
}

.card-note-cmp {
  margin: 0 20px;
  padding: 10px;
  align-items: center;
  background: #fafafa;
  border-radius: 5px;
  &.dark {
    background: #242425;
  }
}

.header-card-call {
  margin: 0 20px;
  height: 7rem;
  display: flex;
  align-items: center;
  background: #fafafa;
  border-radius: 5px;
  &.dark {
    background: #242425;
  }
}

.header-card-call {
  margin: 0 20px;
  height: 7rem;
  display: flex;
  align-items: center;
  background: #fafafa;
  border-radius: 5px;
  &.dark {
    background: #242425;
  }
}
.header-card-call-child {
  width: 49%;
  border-radius: 5px;
  border: 1.2px solid #434245;
  &.dark {
    border: 1.2px solid #e0e0e0;
  }
  text-align: center;
}

.header-card-call-child-name {
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 15px;
}

.container-star {
  display: flex;
  align-items: center;
  cursor: pointer;

  .star {
    margin: 10px;
    fill: rgba($color: #000000, $alpha: 0);
    stroke-width: 1;
  }

  .star-active {
    color: #ffd12d;
    font-weight: light;
    fill: #ffd12d;
  }
}
</style>

<style>
.comment-vhtml > p {
  margin: 0 !important;
}
</style>
