export default [
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        visible: true
    },
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        visible: true
    },
    {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Program",
        model: null,
        options: [
            { id: 1, name: "Business" },
            { id: 2, name: "Boost Credit" },
            { id: 3, name: "Credit Experts" },
            { id: 4, name: "Debt Solution" },
            { id: 5, name: "Tax Research" },
            { id: 6, name: "Court Info" },
            { id: 7, name: "Specialist" },
            { id: 8, name: "KeyBook" },
            { id: 9, name: "Paragon" },
        ],
        reduce: "id",
        selectText: "name",
        cols: 12,
        visible: true
    },
    {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Module",
        model: null,
        options: [
            { id: 2, name: "CRM" },
        ],
        reduce: "id",
        selectText: "name",
        cols: 6,
        visible: true
    },

]
